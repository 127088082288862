<template>
  <header>
    <div class="logo-box">
      <img
        src="@/assets/images/Logo talenttest_beta.png"
        class="logo-box__logo"
        alt=""
      />
    </div>
  </header>
</template>

<script setup></script>

<style lang="scss" scoped>
header {
  padding: 16px;
  max-height: 56px;
  position: fixed;
  z-index: 1000;
  width: 100%;
  background: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.logo-box {
  &__logo {
    max-width: 108px;
    max-height: 32px;
    cursor: pointer;
  }
}
.communication-box {
  display: flex;
  gap: 16px;
  & img {
    cursor: pointer;
    max-width: 24px;
  }
}
</style>
